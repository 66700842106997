<template>
  <v-container fluid style="margin:0px">
    <v-card elevation="0"
      style="margin-top: 0px !important; margin-bottom: -30px !important; overflow: hidden;background-color:transparent"
      class="px-5 py-1">
      <v-row class="custom-gray-background fill-width d-flex" v-if="mostrarFiltro">

        <v-col cols="12" sm="6" md="3">
          <span v-if="modos.length > 0">Filtrar por</span>
          <v-select v-model="selectMode" :items="modos" v-if="modos.length > 0" label="Filtrar" dense
            solo-inverted></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="3" v-if="selectMode === 'Vencimento'">
          <span v-if="selectMode === 'Vencimento'">Vencimento - Inicial</span>
          <v-menu v-model="isStartDatePickerOpen" :close-on-content-click="false" transition="scale-transition"
            offset-y>
            <template #activator="{ on }">
              <v-text-field v-model="selectedStartDate" label="Data Inicial" dense solo-inverted readonly
                v-on="on"></v-text-field>
            </template>
            <v-date-picker no-title v-model="localSelectedStartDateISO"
              @input="handleStartDateSelection"></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" sm="6" md="3" v-if="selectMode === 'Vencimento'">
          <span v-if="selectMode === 'Vencimento'">Vencimento - Final</span>
          <v-menu v-model="isEndDatePickerOpen" :close-on-content-click="false" transition="scale-transition" offset-y>
            <template #activator="{ on }">
              <v-text-field v-model="selectedEndDate" label="Data Final" dense solo-inverted readonly
                v-on="on"></v-text-field>
            </template>
            <v-date-picker no-title v-model="localSelectedEndDateISO" @input="handleEndDateSelection"></v-date-picker>
          </v-menu>
        </v-col>


        <v-col cols="12" sm="6" md="4" v-if="selectMode != 'Vencimento'">
          <span>Informação</span>
          <v-text-field v-model="selectedInf" :label="'Informe o ' + selectMode" dense solo-inverted></v-text-field>
        </v-col>

      </v-row>

      <v-divider></v-divider>

      <v-card-actions class="justify-end">
        <v-btn @click="alternarVisibilidade" color="#4682B4" small class="mr-2"
          style="font-size:14px !important;font-weight:bold">
          {{ mostrarFiltro ? 'Ocultar Filtro' : 'Mostrar Filtro' }}
          <v-icon style="margin-left:8px" dark>{{ mostrarFiltro ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
        </v-btn>

        <v-btn @click="limparFiltro" v-if="mostrarFiltro" color="#4682B4" small outlined class="mr-2"
          style="font-size:14px !important;font-weight:bold">
          Limpar
        </v-btn>
        <v-btn @click="filtrar" v-if="mostrarFiltro" color="#4682B4" small
          style="font-size:14px !important;font-weight:bold">
          Filtrar <v-icon style="margin-left:8px" dark>mdi-filter</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>




<script>

export default {

  props: {
    modos: Array,
    tipos: Array,
    calendar: String,
  },

  created() {


    if (this.modos.length > 0) {
      this.selectMode = this.modos[0];
    }

    this.selectedEndDate = this.formatDateBR(new Date());


    this.selectedStartDate = this.getFirstDayOfMonth();



  },


  data() {
    return {


      mostrarFiltro: true,
      localSelectedEndDateISO: "",
      localSelectedStartDateISO: "",

      localSelectedEndDateISO2: "",
      localSelectedStartDateISO2: "",

      localSelectedEndDateISO3: "",
      localSelectedStartDateISO3: "",


      selectedStartDateISO: "",
      selectedEndDateISO: "",

      selectedStartDate: "",
      selectedEndDate: "",

      isStartDatePickerOpen: false,
      isEndDatePickerOpen: false,


      selectedInf: '',
      selectMode: "",
      user: "",
    };
  },


  methods: {


    alternarVisibilidade() {
      this.mostrarFiltro = !this.mostrarFiltro;
    },

    filtrar() {
      this.$emit("filtrar", {
        selectMode: this.selectMode,
        selectedStartDate: this.selectedStartDate,
        selectedEndDate: this.selectedEndDate,
        selectedInf: this.selectedInf,
      });
    },



    limparFiltro() {
      this.selectedEndDate2 = "",
        this.empresa = 'Todas',
        this.selectedCompany = ["Todas"],
        this.selectedType = "Todos",
        this.selectedOpcao = "Todas",
        this.selectedProduto = "Todos",
        this.selectedInf = "";

      if (this.modos.length > 0) {
        this.selectMode = this.modos[0];
      }

      if (this.tipos.length > 0) {

        this.selectedType = this.tipos[0];
      }


      if (this.produtos.length > 0) {
        this.selectedProduto = this.produtos[0];
      }

      if (this.calendar === null || this.calendar === "mes") {
        // Se calendar for null ou "mes", obtenha o primeiro dia do mês
        this.selectedStartDate = this.getFirstDayOfMonth();
      } else if (this.calendar === "decadas") {
        // Se calendar for "ano", obtenha o primeiro dia do ano
        this.selectedStartDate = this.getFirstDayOf60YearsAgo();
      } else if (this.calendar === "ano") {
        // Se calendar for "ano", obtenha o primeiro dia do ano
        this.selectedStartDate = this.getFirstDayOfYear();
      } else if (this.calendar === "quinzena") {

        this.selectedStartDate = this.getFirstDayOfMonth();
        this.selectedEndDate = this.getEndDateWithOffset(15);

      } else if (this.calendar === "dia") {

        this.selectedStartDate = this.getFirstDayOfMonth();
        this.selectedEndDate = this.getEndDateWithOffset(0);

      } else {
        // Se calendar for qualquer outro valor, obtenha o primeiro dia do mês por padrão
        this.selectedStartDate = this.getFirstDayOfMonth();
      };


      this.selectedEndDate = this.formatDateBR(new Date())

    },


    handleStartDateSelection() {
      this.selectedStartDate = this.convertToBR(this.localSelectedStartDateISO);
      this.isStartDatePickerOpen = false;
    },

    handleEndDateSelection() {
      this.selectedEndDate = this.convertToBR(this.localSelectedEndDateISO);
      this.isEndDatePickerOpen = false;
    },

    handleStartDateSelection2() {
      this.selectedStartDate2 = this.convertToBR(this.localSelectedStartDateISO2);
      this.isStartDatePickerOpen2 = false;
    },

    handleEndDateSelection2() {
      this.selectedEndDate2 = this.convertToBR(this.localSelectedEndDateISO2);
      this.isEndDatePickerOpen2 = false;
    },

    handleStartDateSelection3() {
      this.selectedStartDate3 = this.convertToBR(this.localSelectedStartDateISO3);
      this.isStartDatePickerOpen3 = false;
    },

    handleEndDateSelection3() {
      this.selectedEndDate3 = this.convertToBR(this.localSelectedEndDateISO3);
      this.isEndDatePickerOpen3 = false;
    },


    convertToISO(dateString) {
      // Converte a data do formato "20/10/2023" para "2023-10-20"
      const [day, month, year] = dateString.split("/");
      return `${year}-${month}-${day}`;
    },

    convertToBR(dateString) {
      // Converte a data do formato ISO "2023-10-20" para "20/10/2023"
      const [year, month, day] = dateString.split("-");
      return `${day}/${month}/${year}`;
    },

    getFirstDayOfMonth() {
      const dataAtual = new Date();
      const primeiroDiaDoMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
      return this.formatDateBR(primeiroDiaDoMes);
    },



    getFirstDayOfYear() {
      const dataAtual = new Date();
      const primeiroDiaDoAno = new Date(dataAtual.getFullYear(), 0, 1); // Janeiro é representado como 0
      return this.formatDateBR(primeiroDiaDoAno);
    },

    handleEmpresaSelection() {
      if (this.selectedCompany.includes("Todas")) {
        // Se "Todas" estiver selecionada, desabilita as outras opções
        this.selectedCompany = ["Todas"];
      }
    },

    getEndDateWithOffset(offsetDays) {
      const dataAtual = new Date();
      const primeiroDiaDoAno = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1); // Janeiro é representado como 0

      // Crie a variável endDate aqui
      const endDate = new Date(primeiroDiaDoAno);

      // Adicione o número de dias ao endDate
      endDate.setDate(endDate.getDate() + offsetDays);

      return this.formatDateBR(endDate);
    },

    getFirstDayOf60YearsAgo() {
      const dataAtual = new Date();
      const anoAtual = dataAtual.getFullYear();
      const ano60Atras = anoAtual - 60;
      const primeiroDiaDoAno60Atras = new Date(60, 0, 1); // Janeiro é representado como 0
      return this.formatDateBR(primeiroDiaDoAno60Atras);
    },

    formatDateBR(date) {
      if (!date) return '';
      const parsedDate = new Date(date);
      const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
      const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
      const year = parsedDate.getFullYear();
      return `${day}/${month}/${year}`;
    },


    formatDateUS(date) {
      if (!date) return '';
      const parsedDate = new Date(date);
      const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
      const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
      const year = parsedDate.getFullYear();
      return `${month}/${day}/${year}`;
    },

    convertToISO(dateString) {
      // Converte a data do formato "20/10/2023" para "2023-10-20"
      const [day, month, year] = dateString.split("/");
      return `${year}-${month}-${day}`;
    },

    convertToBR(dateString) {
      // Converte a data do formato ISO "2023-10-20" para "20/10/2023"
      const [year, month, day] = dateString.split("-");
      return `${day}/${month}/${year}`;
    },

  },



};
</script>
<style>
/* Certifique-se de que o arquivo CSS com a classe .custom-col esteja importado aqui */
.custom-col-13 {
  flex-basis: 13%;
  /* 15% é equivalente a 1.5 colunas em uma grade de 10 colunas */
  max-width: 13%;
}

.custom-col-15 {
  flex-basis: 15%;
  /* 15% é equivalente a 1.5 colunas em uma grade de 10 colunas */
  max-width: 15%;
}
</style>