<template>
  <v-app style="background-color:#f8f9fb">
    <v-container style="background-color:#f8f9fb;">
      <v-col cols="12">
        <filtro-component :modos="modos" :calendar="'mes'" @filtrar="pesquisar"></filtro-component>
      </v-col>
    </v-container>
    <v-container style="background-color:#eee" id="financeiro" fluid tag="section">
      <v-row>
        <v-col cols="12">
          <base-material-card icon="mdi-magnify" title="Consulta Documentos" color="rgb(71 130 180)"
            style="margin-top:20px !important; padding-top:10px !important" class="px-5 py-0">
            <v-data-table style="margin-top: 1rem; min-height: 30vh;" :headers="headers" :items="boletoRegistro"
  :items-per-page="50" items-per-page-text="Linhas por página" class="elevation-0 text-center">

  <template v-slot:top>
    <v-progress-linear v-if="isLoading" indeterminate color="rgb(71 130 180)"></v-progress-linear>
  </template>

  <template v-slot:item="row">
    <tr :class="{ 'cor-row': row.index % 2 !== 0 }" >
      <td class="linha" style="width: 8%; font-size:12px; font-weight: bold;">
        {{ row.item.id }}
      </td>
      <td class="linha text-left" style="width: 5%; font-size:12px;">
        <v-tooltip right :color="buscarIcone(row.item.status).color">
          <template #activator="{ on, attrs }">
            <v-icon size="20" v-bind="attrs" v-on="on" :color="buscarIcone(row.item.status).color">
              {{ buscarIcone(row.item.status).icon }}
            </v-icon>
          </template>
          <span>{{ buscarIcone(row.item.status).tooltip }}</span>
        </v-tooltip>
      </td>
      <td class="linha text-center" style="width: 5%; font-size:12px;">
        {{ convertToBR(row.item.vencimento) }}
      </td>
      <td class="linha text-center" style="width: 62%; font-size:12px;">
        {{ row.item.cliente }}
      </td>
      <td class="linha text-center" style="width: 5%; font-size:12px;">
        {{ row.item.referencia }}
      </td>
      <td class="linha text-center" style="width: 5%; font-size:12px;">
        {{ row.item.identificacao }}
      </td>
      <td class="linha text-center" style="width: 5%; font-size:12px;">
        {{ formatarMoeda(row.item.valorOriginal) }}
      </td>
      <td class="linha text-center" style="width: 10%; font-size:12px;">
        <v-chip style="font-size:8px;" :color="getStatus(row.item.empresa)" text-color="white">
          {{ row.item.empresa }}
        </v-chip>
      </td>
      <td class="d-flex justify-center" style="width: 5%; font-size:12px;">
        <v-btn text @click="verMais(row.item)" class="d-flex justify-center">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </td>
    </tr>
  </template>
</v-data-table>

          </base-material-card>
        </v-col>
      </v-row>
    </v-container>


  <v-dialog v-model="showFilter" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        A Diferença entre as datas não pode ser superior a {{ this.dias }} dias.
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilter = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


    <!-- Modal de detalhes ao clicar no botão de ação -->
    <v-dialog persistent v-model="dialog" max-width="900px">
      <v-card>
        <v-toolbar color="indigo">
          <v-card-title style="padding: 0 !important;" class="headline white--text"><strong>Detalhes do Boleto</strong>
          </v-card-title>
        </v-toolbar>

        <v-card-text style="padding: 0 !important;">
          <v-tabs v-model="tab" background-color="indigo" dark centered>
            <v-tab>Registro</v-tab>
            <v-tab @click="buscarBoletos(selectedItem?.id)">Boleto e Emissões</v-tab>
            <v-tab @click="buscarRecebimentos(selectedItem?.id)">Recebimentos</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <!-- Aba Registro -->
            <v-tab-item>
              <v-card-text style="min-height: 56vh;">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field label="ID" :value="selectedItem?.id" readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field label="Vencimento" :value="convertToBR(selectedItem?.vencimento)"
                        readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field label="Identificação" :value="selectedItem?.identificacao" readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field label="Cliente" :value="selectedItem?.cliente" readonly></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field label="Empresa" :value="selectedItem?.empresa" readonly></v-text-field>
                    </v-col>


                    <v-col cols="12" md="3">
                      <v-text-field label="Tipo de Origem" :value="selectedItem?.tipoOrigem" readonly></v-text-field>
                    </v-col>
                
                    <v-col cols="12" md="3">
                      <v-text-field label="Origem" :value="selectedItem?.origem" readonly></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field label="Tipo de Parcela" :value="selectedItem?.tipoParcela" readonly></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field label="Lote" :value="selectedItem?.lote" readonly></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field label="Valor Original" :value="formatarMoeda(selectedItem?.valorOriginal)"
                        readonly></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field label="Valor Acréscimos" :value="formatarMoeda(selectedItem?.valorAcrescimo)"
                        readonly></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field label="Valor Abatimentos" :value="formatarMoeda(selectedItem?.valorAbatimentos)"
                        readonly></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field label="Valor Juros" :value="formatarMoeda(selectedItem?.valorJuros)"
                        readonly></v-text-field>
                    </v-col>

                    
                    <v-col cols="12" md="3">
                      <v-text-field label="Valor Multa" :value="formatarMoeda(selectedItem?.valorMulta)"
                        readonly></v-text-field>
                    </v-col>
                    

                    <v-col cols="12" md="3">
                      <v-text-field label="Saldo" :value="formatarMoeda(selectedItem?.saldo)" readonly></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-tab-item>

            <!-- Aba Boleto e Emissões -->
            <v-tab-item>
              <v-card-text style="min-height: 56vh;">
                <v-simple-table v-if="boletoEmissoes.length > 0">
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>Vencimento</th>
                      <th>Valor Total</th>
                      <th>Nosso Número</th>
                      <th>Configuração</th>
                      <th>Lote</th>
                      <th>Atual</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(emissao, index) in boletoEmissoes" :key="index">
                      <td class="linha">{{ emissao.via }}</td>
                      <td class="linha">{{ convertToBR(emissao.vencimento) }}</td>
                      <td class="linha">{{ formatarMoeda(emissao.valortotal) }}</td>
                      <td class="linha">{{ emissao.nossonumero }}</td>
                      <td class="linha">{{ emissao.nome }}</td>
                      <td class="linha">{{ emissao.lote }}</td>
                      <td class="linha">
                        <v-checkbox class="checkbox-cell" v-model="emissao.atual" disabled color="success"
                          hide-details></v-checkbox>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <div v-else>Nenhum boleto registrado.</div>
              </v-card-text>
            </v-tab-item>

            <!-- Aba Recebimentos -->
            <v-tab-item>
              <v-card-text style="min-height: 56vh;">
                <v-simple-table v-if="boletoRecebimentos.length > 0">
                  <thead>
                    <tr>
                      <th>Recebimento</th>
                      <th>Compensação</th>
                      <th>Forma</th>
                      <th>Identificação</th>
                      <th>Valor Original</th>
                      <th>Multa</th>
                      <th>Juros</th>
                      <th>Total Recebido</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(recebimento, index) in boletoRecebimentos" :key="index">
                      <td class="linha">{{ convertToBR(recebimento.dataRecebimento) }}</td>
                      <td class="linha">{{ convertToBR(recebimento.dataCompensacao) }}</td>
                      <td class="linha">{{ recebimento.forma }}</td>
                      <td class="linha">{{ recebimento.identificacao }}</td>
                      <td class="linha">{{ formatarMoeda(recebimento.valorOriginal) }}</td>
                      <td class="linha">{{ formatarMoeda(recebimento.multa) }}</td>
                      <td class="linha">{{ formatarMoeda(recebimento.juros) }}</td>
                      <td class="linha">{{ formatarMoeda(recebimento.totalRecebido) }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <div v-else>Nenhum recebimento registrado.</div>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="finalizarDialog()" color="red" text>
            <v-icon left>mdi-close</v-icon> Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import FiltroComponent from "./components/FiltroDocs.vue";
import { ContentLoader } from 'vue-content-loader'
import VuetifyMoney from "./components/VuetifyMoney.vue";
import Financeiro from '../../services/financeiro'


export default {
  name: 'Documentos',
  components: {
    apexcharts: VueApexCharts,
    FiltroComponent,
    ContentLoader,
    "vuetify-money": VuetifyMoney
  },

  mounted() {
  },

  data() {
    return {
      modos: ["Vencimento", "Nome do Cliente", "ID Cliente", "ID Geracao", "Contrato", "Nosso Número", "ID Documento"],
      showFilter: false,
      showProgress: false,
      isLoading: false,
      headers: [
        { text: 'ID', value: 'id', align: 'center' },
        { text: 'Status', value: 'status', align: 'left' },
        { text: 'Vencimento', value: 'vencimento', align: 'center' },
        { text: 'Cliente', value: 'cliente', align: 'center' },
        { text: 'Referência', value: 'referencia', align: 'center' },
        { text: 'Identificação', value: 'identificacao', align: 'center' },
        { text: 'Valor Original', value: 'valorOriginal', align: 'center' },
        { text: 'Empresa', value: 'empresa', align: 'center' },
        { text: 'Ação', value: 'acao', sortable: false, align: 'center' },
      ],
      selectMode: 'Vencimento',
      selectedStartDate: "",
      selectedEndDate: "",
      selectedInf: "",
      isStartDatePickerOpen: false,
      isEndDatePickerOpen: false,

      boletoRegistro: [],
      boletoEmissoes: [],
      boletoRecebimentos: [],
      dialog: false,
      selectedItem: null,
      tab: 0
    }
  },

  methods: {
    verMais(item) {
      this.selectedItem = item;
      this.dialog = true;
    },
    buscarIcone(status) {
      switch (status) {
        case 'Cancelado':
          return { icon: 'mdi-close', color: 'red', tooltip: 'Cancelado' };
        case 'Recebido':
          return { icon: 'mdi-circle', color: 'blue darken-3', tooltip: 'Recebido' };
        case 'Negociado':
          return { icon: 'mdi-pause', color: 'orange', tooltip: 'Negociado' };
        case 'Em Atraso':
          return { icon: 'mdi-circle', color: 'red', tooltip: 'Em Atraso' };
        case 'A Receber':
          return { icon: 'mdi-play', color: 'green', tooltip: 'A Receber' };
        default:
          return { icon: 'mdi-help-circle', color: 'grey', tooltip: 'Status Desconhecido' };
      }
    },
    handleEndDateSelection() {
      this.selectedEndDate = this.convertToBR(this.localSelectedEndDateISO);
      this.isEndDatePickerOpen = false;
    },
    handleStartDateSelection() {
      this.selectedStartDate = this.convertToBR(this.localSelectedStartDateISO);
      this.isStartDatePickerOpen = false;
    },


    calcularDiferencaEntreDatas(dataInicial, dataFinal) {
                const data1 = new Date(dataInicial);
                const data2 = new Date(dataFinal);

                // Verifique se as datas são válidas
                if (isNaN(data1) || isNaN(data2)) {
                    return NaN; // Retorna NaN se uma das datas for inválida
                }

                const diferencaEmMilissegundos = data2 - data1;
                const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

                return diferencaEmDias;
      },

    finalizarDialog() {
      this.dialog = false;
      this.tab = 0;
      this.boletoEmissoes = []
      this.boletoRecebimentos = []
    },
    convertToBR(dateString) {
      if (dateString) {
        const [year, month, day] = dateString.split("-");
        return `${day}/${month}/${year}`;
      }
      return
    },
    formatarMoeda(valor) {
      return Number(valor).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      });
    },

    getStatus (status) {
            switch (status) {
            case 'PARQUE DAS FLORES': return 'orange darken-2'
            case 'CEMITERIO PREVIDA' : return 'blue lighten-1'
            case 'MEMORIAL PARQUE MACEIÓ' : return 'green lighten-1'
            case 'PARQUE DO AGRESTE' : return 'cyan lighten-1'
            case 'PREVIDA' : return 'blue lighten-1'

              default: 'success'
            }
      },


    converteDataParaFormatoISO(dataNoFormatoBR) {
      const partes = dataNoFormatoBR.split('/');
      if (partes.length === 3) {
        const [dia, mes, ano] = partes;
        return `${ano}-${mes}-${dia}`;
      }
      return null;
    },
    async pesquisar(valores) {
      this.isLoading = true;

      try {
        this.selectMode = valores.selectMode;
        this.selectedStartDate = valores.selectedStartDate;
        this.selectedEndDate = valores.selectedEndDate;
        this.selectedInf = valores.selectedInf;

        const formattedStartDate = this.converteDataParaFormatoISO(this.selectedStartDate);
        const formattedEndDate = this.converteDataParaFormatoISO(this.selectedEndDate);


        const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

        if (diferencaEmDias > 31 && this.selectMode === 'Vencimento') {
          this.isLoading = false;
          this.showFilter = true;
          this.showProgress = false;
          this.dias = 31;
          return 
        }

        const response = await Financeiro.documentos_registro(
          this.selectMode,
          formattedStartDate,
          formattedEndDate,
          this.selectedInf
        );

        if (response.status === 200 && response.data) {
          this.boletoRegistro = response.data;
        } else {
          console.warn("Nenhum dado retornado ou resposta inesperada.");
        }
      } catch (error) {
        console.error("Erro ao buscar registros:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async buscarBoletos(doc) {
      if (this.boletoEmissoes.length > 0) {
        return;
      }

      try {
        const response = await Financeiro.documentos_emissoes(doc);
        this.boletoEmissoes = response.data;
      } catch (error) {
        console.error("Erro ao buscar boletos:", error);
      } finally {
        this.isLoadingBoletos = false;
      }
    },

    async buscarRecebimentos(doc) {
      if (this.boletoRecebimentos.length > 0) {
        return;
      }

      try {
        const response = await Financeiro.documentos_recebimentos(doc);
        this.boletoRecebimentos = response.data;
      } catch (error) {
        console.error("Erro ao buscar recebimentos:", error);
      } finally {
        this.isLoadingRecebimentos = false;
      }
    }
  },
}

</script>


<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.linha {
  font-size: 11px !important;
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.no-hover-effect.v-data-table tbody tr:hover {
  color: #3055a4;
}

.cor-row {
  background-color: #90d34c21;
  transition: none !important;
}

.checkbox-cell {
  margin: 0 !important;
  padding: 0 !important;
}

.v-progress-circular {
  margin: 1rem;
}

.custom-gray-background {
  height: 90px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto !important;
  margin: -12px;
}
</style>
